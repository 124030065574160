<template>
  <div class="m-2">
    <arq-previews-page />
    <h1 class="title">
      {{ title }}
    </h1>
    <div v-if="typeTurn === 'elegir'">
      <center>
        <div class="row match-height m-4 ">
          <div
            v-for="card in cards"
            :key="card.id"
            class="col-md-4"
          >
            <div>
              <b-card
                style="max-width: 22rem;"
                :title="card.title"
              >
                <center>
                  <feather-icon
                    class="my-2"
                    size="42"
                    :icon="card.icon"
                  />
                </center>
                <b-card-text style="height: 100px">
                  {{ card.description }}
                </b-card-text>

                <center>
                  <b-button
                    target="blank"
                    :style="'background-color:' + color"
                    :href="card.externalLink"
                    @click="elegirTipoTurno(card)"
                  >
                    Solicitar
                  </b-button>
                </center>
              </b-card>
            </div>
          </div>
        </div>
      </center>
    </div>
    <div v-else>
      <h1 class="title">
        {{ turn.title }}
      </h1>
      <feather-icon
        class="my-2 cursor-pointer ml-4"
        size="42"
        icon="ArrowLeftIcon"
        @click="elegirTipoTurno({ param: 'elegir' })"
      />
      <center>
        <div style="width: 500px">
          <b-card>
            <form>
              <div
                v-for="(form, index) in turn.form"
                :key="index"
              >
                <label :for="form.key">{{ form.label }}</label>
                <b-form-select
                  v-if="form.type === 'documentType'"
                  :id="form.key"
                  v-model.number="data[form.key]"
                  value-field="id"
                  text-field="description"
                  :options="listTypesDocuments"
                />
                <b-form-input
                  v-else
                  v-model="data[form.key]"
                  :type="form.type"
                  :name="form.label"
                />
              </div>

              <b-button
                class="mt-4"
                target="blank"
                variant="success"
                @click="solicitarTurno(data)"
              >
                Solicitar
              </b-button>
            </form>
          </b-card>
        </div>
      </center>
    </div>
    <b-modal
      id="modal-turno"
      title="Ingresar"
    >
      <div class="text-center">
        <h3>
          Turno <span>{{ res.turno }}</span>
        </h3>
        <h3>
          Numero asginado: <span>{{ res.numero }}</span>
        </h3>
        <h3>
          Tiempo aproximado <span>{{ res.tiempo }}</span>
        </h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      color: 'blue',
      title: 'Turno',
      res: {},
      turn: {},
      listTypesDocuments: [],
      cards: [
        {
          id: 1,
          turnType: 2,
          title: 'Virtual',
          param: 'virtual',
          icon: 'MonitorIcon',
          description: 'Un asesor se comunicara contigo.',
          form: [
            {
              key: 'idType',
              label: 'Tipo de documento',
              type: 'documentType',
            },
            {
              key: 'idNumber',
              label: 'Numero de documento',
              type: 'number',
            },
            {
              key: 'phone',
              label: 'Numero de telefono',
              type: 'number',
            },
            {
              key: 'email',
              label: 'Dirección de correo',
              type: 'email',
            },
          ],
        },
        {
          id: 2,
          turnType: 1,
          title: 'Presencial',
          icon: 'UsersIcon',
          param: 'presencial',
          form: [
            {
              key: 'idType',
              label: 'Tipo de documento',
              type: 'documentType',
            },
            {
              key: 'idNumber',
              label: 'Numero de documento',
              type: 'number',
            },
          ],
          description: 'Te atenderan en la oficina.',
        },
      ],
      data: {},
    }
  },
  computed: {
    typeTurn() {
      this.findTurn()
      return this.$route.params.type
    },
  },
  created() {
    this.getDocmentTypes()
  },
  methods: {
    findTurn() {
      this.data = {}
      this.turn = this.cards.find(el => el.param === this.$route.params.type)
    },
    elegirTipoTurno(turno) {
      this.data = {}
      this.$router.push(`/portal-turno/${turno.param}`)
      // console.log('turno', turno)
    },
    getDocmentTypes() {
      axios
        .get('/app/client/documenttypes')
        .then(res => {
          this.listTypesDocuments = res.data.list
        })
        .catch()
    },
    solicitarTurno() {
      this.data.turnType = this.turn.turnType
      axios
        .post('/app/turn/request', this.data)
        .then(res => {
          this.$bvModal.show('modal-turno')
          let timeMin = res.data.timeSeg / 60
          let timeHs = 0
          if (timeMin > 60) {
            timeHs = Math.floor(timeMin / 60)
            timeMin = timeHs * 60 - timeMin
          }
          let timeText = ''
          if (timeHs > 0) {
            timeText = `${timeHs} Hr(s) ${timeMin} Min.`
          } else {
            timeText = `${timeMin} Min.`
          }
          this.res = {
            turno: this.typeTurn,
            numero: res.data.idTurn,
            tiempo: timeText,
          }
        })
        .catch()
        .finally(() => {})
    },
  },
}
</script>

<style>
.title {
  position: relative;
  margin-left: 5em;
}
</style>
